<template>
  <v-container fluid fill-height>
    <v-row wrap align-center>
      <v-col>
        <v-card>
          <v-card-title>¡Hola!</v-card-title>
          <v-card-text>
            <v-alert type="info" outlined
              >Ingrese su <strong>Numero de Teléfono</strong> o
              <strong>Correo Electrónico</strong> para acceder a su
              cuenta.</v-alert
            >
            <v-text-field
              solo
              v-model="user"
              outlined
              name="user"
              required
              autofocus
              class="mb-n4"
              :error="errorUser"
              :disabled="step == 2"
              :prepend-icon="iconoUsuario"
              @keydown.enter="step1()"
            ></v-text-field>
            <v-alert type="error" outlined v-if="errorLogin">{{
              errorLogin
            }}</v-alert>
            <div v-if="step == 2">
              <v-text-field
                v-model="password"
                prepend-icon="mdi-pound"
                solo
                outlined
                name="password"
                type="password"
                ref="password"
                required
                autofocus
                :error="errorPassword"
                class="mt-n4"
                @keydown.enter="step2()"
              ></v-text-field>
              <vue-recaptcha
                sitekey="6LcntjoaAAAAACfPmWJq7CMOzC67yRuXH_J13-fb"
                class="mb-4 mt-n4"
                loadRecaptchaScript
                @verify="onVerify"
                ref="recaptcha"
                v-if="false"
              ></vue-recaptcha>
            </div>
            <v-btn
              block
              color="primary"
              class="mb-4"
              :loading="loading"
              @click="step == 1 ? step1() : step2()"
              >{{ step == 1 ? "Continuar" : "Ingresar" }}</v-btn
            >
            <p class="text-center">
              <strong>¿Todavia no tenes una cuenta?</strong>
            </p>
            <v-btn block text color="primary" @click="registro()" class="mt-n4"
              >Registrarme</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import { mapMutations } from "vuex";

export default {
  name: "Ingresar",
  metaInfo: {
      title: 'Ingrese a su Cuenta',
    },
  components: { VueRecaptcha },
  data: () => ({
    user: "",
    password: "",
    medio: null,
    recaptcha: true, // poner en false para habilitar
    step: 1,
    errorUser: false,
    errorPassword: false,
    errorLogin: false,
    loading: false,
  }),
  methods: {
    ...mapMutations(["setUsuario"]),
    registro() {
      this.$router.push({
        name: "Registro",
        params: { volver: this.$route.params.volver },
      });
    },
    onVerify(recaptcha) {
      this.recaptcha = recaptcha;
    },
    step1() {
      this.errorUser = false;
      this.errorLogin = false;
      if (this.validarEmail(this.user)) {
        this.medio = "email";
      } else {
        if (this.validarTel(this.user)) {
          this.medio = "sms";
        } else {
          this.medio = null;
        }
      }
      if (this.medio) {
        this.step = 2;
      } else {
        this.errorUser = true;
      }
    },
    step2() {
      if (!this.password || this.password.toString().length < 6) {
        this.errorPassword = true;
        this.$refs.password.focus();
      } else {
        this.errorPassword = false;
        if (this.recaptcha) {
          this.login();
        }
      }
    },
    validarEmail(email) {
      const rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return rgx.test(String(email).toLowerCase());
    },
    validarTel(phone) {
      var rgx = /^\d{10}$/;
      return phone.match(rgx);
    },
    async login() {
      this.loading = true;
      try {
        let login = await this.axios.post("/ingresar", {
          user: this.user,
          password: this.password,
          recaptcha: this.recaptcha,
        });
        if (!login.data.token || !login.data.usuario)
          throw {
            status:
              "Hubo un error al procesar su solicitud. Por favor, reintente.",
          };
        this.iniciarSesion(login.data);
      } catch (error) {
        this.errorLogin = error.status ? error.status : "Datos incorrectos";
        this.step = 1;
        this.password = "";
      }
      this.loading = false;
    },
    iniciarSesion(data) {
      this.setUsuario(data);
      if (this.$route.params.volver) {
        this.$router.push(this.$route.params.volver);
      } else {
        this.$router.push({ name: "Cuenta" });
      }
    },
  },
  computed: {
    iconoUsuario() {
      return this.medio
        ? this.medio == "sms"
          ? "mdi-cellphone"
          : "mdi-email"
        : null;
    },
  },
  created() {
    if (this.$route.params.token && this.$route.params.usuario) {
      this.loading = true;
      this.iniciarSesion({
        token: this.$route.params.token,
        usuario: this.$route.params.usuario
      });
    }
  },
};
</script>
